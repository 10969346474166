
import { NavBar } from '../components/NavBar'
import { ContactUs, Home,  AboutUs, Benefits} from '../components/'

export const HomePage = () => {
  // const wi = window.screen.width;
  // const he = window.screen.height;
  return (
    <>
      {/* wi: { wi } <br /> he: { he } <br /> */}
            <NavBar />
          <div id="home">
            <Home/>
          </div>
          <div id="about">
            <AboutUs />
          </div>
          <div id="benefits">
            <Benefits />
          </div>
          <div id="contact">
            {/* <section className='hidden lg:block'> */}
              <ContactUs />
            {/* </section>
            <section className='block lg:hidden'>
              <ContactUsMovil />
            </section> */}
          </div>
      
    </>
  )
}
